import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Seo from "../components/seo"
import Layout from "../components/layout"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes
  return (
    <Layout>
      <div className="pages">
        <Seo title="" />
        <div className="content">
          {posts.map(post => {
            const title = post.title
            return (
              <p key={post.uri}>
                <Link to={post.uri} itemProp="url">
                  <span itemProp="headline">{parse(title)}</span>
                </Link>
              </p>
            )
          })}
        </div>
        <div className="nav">
          {previousPagePath && <Link to={previousPagePath}>上一页</Link>}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          {nextPagePath && <Link to={nextPagePath}>下一页</Link>}
        </div> 
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!, $title: String!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset,
      filter: { title: { glob: $title } }
    ) {
      nodes {
        id
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
